import React from "react";
import { compose } from "recompose";
import { withTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import { Button } from 'antd';
import { withDocumentTitleUpdate } from "../../../../../../../../hoc/withDocumentTitleUpdate/withDocumentTitleUpdate";

import { getMoviesExport } from "../../../../../../../../services/movies-service/movies.service";

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../services/notification-service/notification.service";

import MoviesViewAbstract from "./MoviesViewAbstract/MoviesViewAbstract";
import MoviesMainView from "./MoviesMainView/MoviesMainView";
import MoviePlayLists from "./playlists/MoviePlayLists/MoviePlayLists";
import ScenePlayLists from "./playlists/ScenePlayLists/ScenePlayLists";

import { MOVIES_ROUTES } from "./movies.routes";

class MoviesView extends MoviesViewAbstract {

  getAdditionalActions = () => {
    const { t } = this.props;
    return (
      <Button onClick={this.onExportMovies}>
        {t('MoviesView.export')}
      </Button>
    );
  };

  getDocumentTitle = () => {
    return this.props.t('MoviesView.documentTitle');
  };

  onExportMovies = () => {
    const message = this.props.t('MoviesView.exportingMsg');
    this.setLoading(message);
    getMoviesExport()
      .then(this.onExportMoviesSuccess)
      .catch(this.onExportMoviesFailure);
  };

  onExportMoviesFailure = () => {
    this.setLoading('');
    const { t } = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('MoviesView.moviesExportFailed')
    });
  };

  onExportMoviesSuccess = response => {
    this.setLoading('');
    const { t } = this.props;
    displaySuccessNotification({
      duration: 0,
      message: t('MoviesView.moviesExportSuccess'),
      description: (
        <CSVLink data={response.data} filename={"exported-movies.csv"}>{t('MoviesView.clickToDownloadCSV')}</CSVLink>
      )
    });
  };

  /**
   * @override
   */
  renderAdditionalPaths = () => {
    return [
      <MoviePlayLists
        path={`${MOVIES_ROUTES.moviePlaylists}/*`}
        key="moviePlaylists"
        contentHeight={this.props.contentHeight}
      />,
      <ScenePlayLists
        path={`${MOVIES_ROUTES.scenePlaylists}/*`}
        key="scenePlaylists"
        contentHeight={this.props.contentHeight}
      />
    ];
  };

  renderMainViewRoute = () => {
    const props = this.getMainViewProps();
    return (
      <MoviesMainView
        {...props}
        additionalActions={this.getAdditionalActions()}
      />
    );
  };

}

export default compose(withTranslation(), withDocumentTitleUpdate)(MoviesView);
