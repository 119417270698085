import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import { Link } from "@reach/router";
import { Button, Card } from "antd";
import PropTypes from 'prop-types';

import ImageComponent from "../../../../../../../../../../components/ImageComponent/ImageComponent";
import LinkButton from "../../../../../../../../../../components/LinkButton/LinkButton";

import {
  getBannerDetailsRoute,
  getBannerSetBannersRoute,
  getBannerSetPropertiesRoute, getPropertyBannerSetBannersRoute
} from "../../../../../../../../../../services/navigation/banners-navigation/banners-navigation-routes.service";
import {
  getBannerById, getBannerSetPropertySaveKey,
  getDefaultBannerSetSaveKey,
  removeBannerSet
} from "../../../../../../../../../../services/banners-service/banners.service";

import { LogController } from "../../../../../../../../../../controllers/log-controller/log.controller";

import './BannerSetCardAbstract.scss';
import { displayErrorNotification } from '../../../../../../../../../../services/notification-service/notification.service';
import { ModalController } from '../../../../../../../../../../controllers/modal-controller/modal.controller';
import ConfirmationModal from '../../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal';
import { findLargesBannerImage } from '../../../../../../../../../../services/images-service/images.service';

/* istanbul ignore file */
class BannerSetCardAbstract extends Component {

  state = {
    order: []
  };

  componentDidMount() {
    this.loadAdditionalBannersIfNeeded();
  }

  addNewBannersIfNeeded = (order, tempOrder, values) => {
    const { isMyBanner } = this.props;
    let useBanner = true;
    let matchBanner;
    for (const data of tempOrder) {
      if (data.index <= 5) { // Only use those items moved before index 6
        matchBanner = values.find(this.matchBanner.bind(this, data.banners_id));
        if (!matchBanner) {
          const index = order.findIndex(this.matchBannerById.bind(this, data.banners_id));
          matchBanner = order.splice(index, 1)[0];
        } else {
          if (isMyBanner) { // There is a possibility that loaded banner is not of this banner set, case when loading default set banner in property banner set
            useBanner = isMyBanner(matchBanner.data.data);
          }
          if (useBanner) {
            matchBanner = matchBanner.data.data;
          }
        }
        if (matchBanner) {
          order.splice(data.index, 0, matchBanner);
        }
      }
    }

    return order;
  };

  showRemoveBannerModal = () => {
    const { t } = this.props;
    const modal = (
      <ConfirmationModal
        title={t("BannersViewHeader.modalConfirmTitle")}
        message={t("BannersViewHeader.modalConfirmMsg")}
        confirm={this.removeBanner}
      />
    );
    ModalController.showModal(modal);
  }

  removeBanner = () => {
    const { bannerSetId, updateBanners } = this.props;
    removeBannerSet(bannerSetId)
      .then(() => {
        updateBanners();
        displayErrorNotification({
          duration: 3,
          message: this.props.t('BannerSets.bannerSetRemoveSuccess')
        });
      })
      .catch(() => {
        displayErrorNotification({
          duration: 3,
          message: this.props.t('BannerSets.bannerSetRemoveFail')
        });
      })
  }

  clearRemovedBanners = (banners, removed) => {
    if (removed && removed.length) {
      let index;
      for (const id of removed) {
        index = banners.findIndex(this.matchBannerById.bind(this, id));
        if (index !== -1) {
          banners.splice(index, 1);
        }
      }
    }

    return banners;
  };

  fillEmptySpaces = (order, itemCount) => {
    let item = 0;
    while (item < itemCount) {
      order.push({});
      item++;
    }

    return order;
  };

  getBannersRoute = () => {
    const { bannerSetId, propertyId } = this.props;
    return propertyId ? getPropertyBannerSetBannersRoute(bannerSetId, propertyId) : getBannerSetBannersRoute(bannerSetId);
  };

  getAdditionalClasses = () => [];

  getClasses = () => {
    const { isDefault } = this.props;
    const classes = [...this.getAdditionalClasses(), 'BannerSetCardAbstract'];
    if (isDefault) {
      classes.push('DefaultSet');
    }

    return classes;
  };

  getMissingBannersIds = (publishedOrder, savedOrder) => {
    const missingBannersIds = [];
    let item = savedOrder.length;
    let currentItem;
    while (item) {
      item--;
      currentItem = savedOrder[item];
      if (!publishedOrder.find(this.matchBannerById.bind(this, currentItem.banners_id)) && !missingBannersIds.find(this.matchBannerByBannerId.bind(this, currentItem.banners_id))) {
        missingBannersIds.push(currentItem.banners_id);
      }
    }

    return missingBannersIds;
  };

  getSavedData = () => {
    const { bannerSetId, propertyId } = this.props;
    let data = {};
    const key = getDefaultBannerSetSaveKey(bannerSetId);
    let savedData = localStorage.getItem(key);
    if (savedData) {
      data = JSON.parse(savedData);
    }
    if (propertyId) {
      const bannerKey = getBannerSetPropertySaveKey(bannerSetId, propertyId);
      let bannerSetData = localStorage.getItem(bannerKey);
      if (bannerSetData) {
        const { order, removedBanners } = JSON.parse(bannerSetData);
        if (order) {
          data.order = (data.order || []).concat(order);
        }
        if (removedBanners) {
          data.removedBanners = (data.removedBanners || []).concat(removedBanners);
        }
      }
    }

    return data;
  };

  isPrimaryBanner = ({ index }) => index <= 5;

  loadAdditionalBannersIfNeeded = () => {
    const { bannerOrder } = this.props;
    const { order } = this.getSavedData();
    if (order) {
      const primaryBanners = order.filter(this.isPrimaryBanner);
      if (primaryBanners.length) {
        const missingBanners = this.getMissingBannersIds(bannerOrder, primaryBanners);
        if (missingBanners.length) {
          const promises = missingBanners.map(getBannerById);
          Promise.all(promises)
            .then(this.setBannerOrder)
            .catch(this.onRequestFailure);
        } else {
          this.setState({ order: bannerOrder });
        }
      } else {
        this.setState({ order: bannerOrder });
      }
    } else {
      this.setState({ order: bannerOrder });
    }
  };

  matchBanner = (id, response) => id === response.data.data.id;

  matchBannerByBannerId = (banners_id1, { banners_id }) => banners_id1 === banners_id;

  matchBannerById = (id1, { id }) => id1 === id;

  onRequestFailure = (error) => {
    LogController.logError(error);
  };

  renderActions = () => {
    const {
      allActionsIncluded,
      bannerSetId,
      propertyCount,
      totalBannerCount,
      showEditDefaultBannerSetsModal,
      parentSet,
      t
    } = this.props;

    const actions = [];

    if (!!parentSet) {
      actions.push((
        <Button
          key={`EditSet_${bannerSetId}`}
          className="LinkButton"
          onClick={this.showRemoveBannerModal}
        >
          {t('BannerSetCard.deleteSet')}
        </Button>
      ));
    }
    if (showEditDefaultBannerSetsModal) {
      actions.push((
        <Button
          key={`DeleteSet_${bannerSetId}`}
          className="LinkButton"
          onClick={showEditDefaultBannerSetsModal.bind(this, bannerSetId)}
        >
          {t('BannerSetCard.editSet')}
        </Button>
      ));
    }
    actions.push((
      <LinkButton href={this.getBannersRoute()}
        key="viewAllBanners">
        {t('BannerSetCard.viewAllBanners', { count: totalBannerCount })}
      </LinkButton>
    ));
    if (allActionsIncluded) {
      actions.push((
        <LinkButton href={getBannerSetPropertiesRoute(bannerSetId)}
          key="viewAllProperties">
          {t('BannerSetCard.viewAllProperties', { count: propertyCount })}
        </LinkButton>
      ));
    }
    return (
      <div className="Actions">
        {actions}
      </div>
    );
  };

  renderBanner = ({ exclusiveExcluded, id, image, publishedDate, status, title }, index) => {
    const url = findLargesBannerImage(image);
    const classes = ['Banner'];
    if (status) {
      classes.push('Enabled');
    }
    return id ? (
      <Link to={getBannerDetailsRoute(id)}
        key={id}>
        <Card className={classes.join(' ')}
          title={this.renderBannerTitle(title, index + 1)}>
          {this.renderBannerStates(exclusiveExcluded, publishedDate, status, title)}
          {this.renderBannerCover(url)}
        </Card>
      </Link>
    ) : (<div className="Banner Hidden"
      key={index} />);
  };

  renderBannerCover = (url) => {
    return url ? (
      <ImageComponent url={url} />
    ) : this.renderNoImageSet();
  };

  renderBannerOrder = () => {
    const { maxElementCount } = this.props;
    const { order: bannerOrder } = this.state;
    const order = this.fillEmptySpaces(cloneDeep(bannerOrder), maxElementCount - bannerOrder.length);
    return (
      <div className="BannersOrder">
        {order.slice(0, 6).map(this.renderBanner)}
      </div>
    );
  };

  renderBannerStates = (exclusiveExcluded, publishedDate, status, title) => {
    let view = null;
    const { doNotShowStates, t } = this.props;
    if (!doNotShowStates) {
      const states = [];
      if (exclusiveExcluded && exclusiveExcluded.exclusive_excluded.length) {
        states.push(this.renderBannerState((
          <i className="fas fa-lock" />
        ), t('BannerSetCard.hasPropertyExclusivity'), 'exclusivity'));
      }
      if (title) {
        states.push(this.renderBannerState((
          <i className="fas fa-film" />
        ), title, 'movieName'));
      }
      if (publishedDate) {
        states.push(this.renderBannerState((
          <i className="fas fa-calendar-alt" />
        ), t('BannerSetCard.hasSchedule'), 'hasSchedule'));
        states.push(this.renderBannerState((
          <i className="fas fa-check" />
        ), t('BannerSetCard.published'), 'published'))
      }
      view = (
        <div className="BannerStates">
          {states}
        </div>
      );
    }

    return view;
  };

  renderBannerState = (state, tooltip, key) => {
    return (
      <div className="BannerState" key={key} title={tooltip}>
        {state}
      </div>
    );
  };

  renderBannerTitle = (title, index) => {
    const { t } = this.props;
    return (
      <div className="BannerTitle">
        <div className="Numbering">{t('BannerSetCard.number', { index })}</div>
        -
        <div className="Caption" title={title}>
          {title || t('BannerSetCard.noCaption')}
          {title ? <span>{title}</span> : null}
        </div>
      </div>
    );
  };

  renderNoImageSet = () => {
    const { t } = this.props;
    return (
      <div className="NoImage">
        {t('BannerSetCard.noImageSet')}
      </div>
    );
  };

  renderTitle = () => {
    const { title, showSets, onClick } = this.props;
    const icon = showSets ? 'fa-minus' : 'fa-plus';

    return (
      <div className="Title" onClick={(e) => {
        if (e.target.tagName.toLowerCase() !== 'button') {
          onClick && onClick();
        }
      }}>
        <span>{title}</span>
        {this.renderActions()}
        {onClick && <i className={`toggleIcon fas fa-light ${icon}`} />}
      </div>
    );
  };

  setBannerOrder = (values) => {
    const { bannerOrder } = this.props;
    const { order: tempOrder, removedBanners } = this.getSavedData();
    let order = this.clearRemovedBanners(bannerOrder, removedBanners);
    order = this.addNewBannersIfNeeded(order, tempOrder, values);
    this.setState({ order });
  };

  render() {
    const { showSets } = this.props;

    return (
      <Card
        className={this.getClasses().join(' ')}
        title={this.renderTitle()}
        bodyStyle={{ display: showSets ? 'block' : 'none' }}
      >
        {this.renderBannerOrder()}
      </Card>
    );
  }
}

BannerSetCardAbstract.propTypes = {
  allActionsIncluded: PropTypes.bool,
  bannerOrder: PropTypes.array.isRequired,
  bannerSetId: PropTypes.number.isRequired,
  doNotShowStates: PropTypes.bool,
  isDefault: PropTypes.bool,
  isMyBanner: PropTypes.func,
  maxElementCount: PropTypes.number,
  propertyId: PropTypes.number,
  propertyCount: PropTypes.number,
  t: PropTypes.func,
  title: PropTypes.string.isRequired,
  totalBannerCount: PropTypes.number.isRequired
};

export default BannerSetCardAbstract;
