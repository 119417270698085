import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import PropTypes from 'prop-types';
import { AutoComplete, Button, Input, DatePicker, Select, Row, Col, Collapse, Radio } from "antd";
import BaseCheckboxList from "../../../../../../../../../../components/BaseCheckboxList/BaseCheckboxList";
import ModalDialog from '../../../../../../../../../../components/modal/ModalDialog/ModalDialog';
import ImageComponent from "../../../../../../../../../../components/ImageComponent/ImageComponent";

import { searchCustomPreview } from "../../../../../../../../../../services/video-service/video.service";
import {
  disableBanner,
  enableBanner,
  getBannerById,
  getBannerSets,
  editBanner
} from '../../../../../../../../../../services/banners-service/banners.service';
import { convertMomentToDate } from "../../../../../../../../../../services/date-service/date.service";
import {
  momentFormats,
  formatYYYYMMDD
} from '../../../../../../../../../../services/date-service/date.service';
import {
  displaySuccessNotification,
  displayErrorNotification
} from "../../../../../../../../../../services/notification-service/notification.service";
import { getProperties } from "../../../../../../../../../../services/properties-service/properties.service";
import moment from 'moment';

import { LogController } from "../../../../../../../../../../controllers/log-controller/log.controller";

import { getOnPropertiesData } from '../../../../../../../../../../services/util-service/util.service';
import './EditBannerModal.scss';

class EditBannerModal extends Component {
  filterDelay = 300;

  modalRef = React.createRef();

  startingData = {};

  state = {
    banners: [],
    caption: '',
    dataLoaded: false,
    enabled: 1,
    excluded: [],
    exclusive: [],
    image: [],
    overlay_text: '',
    overlay_title: '',
    overlay_subtitle: '',
    properties: [],
    publishedDate: '',
    publishedEnd: '',
    selectedSets: [],
    tags_id: '',
    title: '',
    url: '',
    videoClipUrl: '',
    movies_id: '',
    scenes_id: '',
    join_button_text: '',
    join_button_color: '',
    join_button_background_color: '',
    join_button_position: 0
  };

  componentDidMount() {
    const message = this.props.t('EditBannerModal.loadingDataMsg');
    const modal = this.getModal();
    if (modal) {
      modal.setLoading(message);
    }
    const promises = [
      getBannerById(this.props.bannerId),
      getBannerSets(),
      getProperties()
    ];
    Promise.all(promises)
      .then(this.loadData)
      .catch(this.onLoadDataFailure);
  };

  getActions = () => {
    const { t } = this.props;
    return [(
      <Button onClick={this.onCancel.bind(this)}
        key="cancelBtn">
        {t('EditBannerModal.cancel')}
      </Button>
    ), (
      <Button onClick={this.onSaveChanges.bind(this)}
        type="primary"
        key="saveChangesBtn"
        disabled={!this.hasDataChanged()}>
        {t('EditBannerModal.saveChanges')}
      </Button>
    )];
  };

  getBannerSetId = ({ id }) => id;

  getBannerStatusUpdatePromise = () => {
    let promise;
    const { enabled: initialStatus } = this.startingData;
    const { enabled } = this.state;
    if (initialStatus !== enabled) {
      const { bannerId } = this.props;
      promise = enabled ? enableBanner(bannerId) : disableBanner(bannerId);
    } else {
      promise = Promise.resolve();
    }

    return promise;
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getModalTitle = () => {
    return (
      <div>
        <span>{this.props.t('EditBannerModal.editBanner')}</span>
        <span style={{ opacity: '.3' }}> - {this.props.bannerId}</span>
      </div>
    )
  };

  getPublishDate = (key) => {
    const data = {};
    const value = this.state[key];
    if (value) {
      data[key] = convertMomentToDate(value);
    } else {
      const prevValue = this.startingData[key];
      if (prevValue) {
        data[key] = null;
      }
    }

    return data;
  };

  hasDataChanged = () => {
    return JSON.stringify(this.state) !== JSON.stringify(this.startingData);
  };

  loadData = (values) => {
    const {
      banners_sets = [],
      banners_on_properties_data,
      movies_id,
      scenes_id,
      studios_id,
      title,
      url,
      caption,
      videoClipUrl,
      movie_playlists_id,
      overlay_text,
      overlay_title,
      overlay_subtitle,
      scene_playlists_id,
      publishedDate,
      publishedEnd,
      status,
      tags_id,
      image,
      join_button_text,
      join_button_color,
      join_button_background_color,
      join_button_position
    } = values[0].data.data;
    const banners = values[1].data.data;
    const selectedSets = banners_sets.map(this.getBannerSetId);
    const { properties = [] } = values[2].data.data;
    const { exclusive = [], excluded = [] } = getOnPropertiesData(banners_on_properties_data, properties);

    const state = {
      banners: banners.sort(this.sortByName),
      exclusive: exclusive.map(item => item.id),
      excluded: excluded.map(item => item.id),
      caption,
      dataLoaded: true,
      enabled: status,
      movies_id,
      scenes_id,
      studios_id,
      movie_playlists_id,
      overlay_text,
      overlay_title,
      overlay_subtitle,
      properties: properties.sort(this.sortByName),
      publishedDate: publishedDate ? formatYYYYMMDD(publishedDate) : null,
      publishedEnd: publishedEnd ? formatYYYYMMDD(publishedEnd) : null,
      scene_playlists_id,
      selectedSets,
      title,
      url,
      videoClipUrl,
      tags_id,
      image,
      join_button_text,
      join_button_color,
      join_button_background_color,
      join_button_position
    };

    Object.assign(this.startingData, state);
    this.setState(state);
    const modal = this.getModal();
    if (modal) {
      modal.setLoading('');
    }
  };

  mapSelectItems = ({ id, name }) => {
    return (
      <Select.Option value={id}
        key={id}>
        {name}
      </Select.Option>
    );
  };

  onBannerEditFailure = (err) => {
    const msgObj = err?.response?.data?.errors || {};
    const msgText = Object.values(msgObj) || '';

    this.getModal().clearLoading();
    displayErrorNotification({
      duration: 6,
      message: this.props.t('EditBannerModal.editBannerFailure'),
      description: msgText ? `${msgText?.toString()}!` : ''
    });
  };

  onBannerEditSuccess = () => {
    this.getModal().closeModal();
    displaySuccessNotification({
      duration: 3,
      message: this.props.t('EditBannerModal.editBannerSuccess')
    });
    this.props.onBannerEdit();
  };

  onCancel = () => {
    this.getModal().closeModal();
  };

  onChange = (key, event) => {
    let value = event;
    if (event) {
      value = event.target ? event.target.value : event;
    }
    this.setState({
      [key]: value
    });
  };

  onLoadDataFailure = (error) => {
    LogController.logError(error);
  };

  onSaveChanges = () => {
    const { bannerId, t } = this.props;
    const message = t('EditBannerModal.savingMsg');
    this.getModal().setLoading(message);

    const {
      movies_id,
      scenes_id,
      title,
      url,
      caption,
      videoClipUrl,
      movie_playlists_id,
      scene_playlists_id,
      selectedSets,
      studios_id,
      tags_id,
      overlay_title,
      overlay_text,
      overlay_subtitle,
      exclusive,
      excluded,
      properties = [],
      join_button_text,
      join_button_color,
      join_button_background_color,
      join_button_position
    } = this.state;

    const bannersOnPropertiesDataPost = properties.map(({ id }) => {
      const data = {
        properties_id: id,
        exclusive_excluded: null
      };

      if (exclusive.includes(id)) {
        data.exclusive_excluded = 'exclusive';
      } else if (excluded.includes(id)) {
        data.exclusive_excluded = 'excluded';
      }

      return data;
    });

    const postData = {
      bannerSets: selectedSets,
      caption,
      title,
      url,
      videoClipUrl,
      overlay_text,
      overlay_title,
      overlay_subtitle,
      bannersOnPropertiesData: bannersOnPropertiesDataPost,
      movie_playlists_id,
      scene_playlists_id,
      studios_id,
      tags_id,
      movies_id,
      scenes_id,
      join_button_text,
      join_button_color,
      join_button_background_color,
      join_button_position
    };

    Object.assign(postData, this.getPublishDate('publishedDate'));
    Object.assign(postData, this.getPublishDate('publishedEnd'));

    const promises = [
      editBanner(bannerId, postData),
      this.getBannerStatusUpdatePromise()
    ];

    Promise.all(promises)
      .then(this.onBannerEditSuccess)
      .catch(this.onBannerEditFailure);
  };

  onMultipleSelectChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  onVideoClipUrlSearch = (searchText) => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.timeoutId = setTimeout(this.searchItems.bind(this, searchText), this.filterDelay);
  };

  renderBannerImage = () => {
    const { image } = this.state;
    let img = null;
    const imagesCount = image && image.length;
    if (imagesCount) {
      let data = image[imagesCount - 3];
      if (!data) {
        data = image[imagesCount - 1];
      }
      img = (
        <ImageComponent url={data.url} />
      );
    }
    return img;
  };

  renderDatePicker = (label, value, format, onChange) => {
    const children = (
      <DatePicker
        showToday
        showTime
        value={moment(value).isValid() ? moment(value) : null}
        format={format}
        onChange={onChange}
      />
    );
    return this.renderField(label, children);
  };

  renderEnabledDisabled = () => {
    const { t } = this.props;
    const children = this.renderEnabledDisabledSelect();
    return this.renderField(t('EditBannerModal.bannerStatus'), children);
  };

  renderEnabledDisabledSelect = () => {
    const { t } = this.props;
    const { enabled } = this.state;
    return (
      <Select onChange={this.onChange.bind(this, 'enabled')}
        value={enabled}>
        <Select.Option value={1} key="enabled">
          {t('EditBannerModal.enabled')}
        </Select.Option>
        <Select.Option value={0} key="disabled">
          {t('EditBannerModal.disabled')}
        </Select.Option>
      </Select>
    );
  };

  renderField = (label, children) => {
    return (
      <div className="EditBannerModal-inner">
        <span className="EditBannerModal-inner-label">{label}:</span>
        {children}
      </div>
    );
  };

  renderInputField = (value, key, label, placeholder) => {
    const children = <Input value={value} onChange={this.onChange.bind(this, key)} placeholder={placeholder} />;
    return this.renderField(label, children);
  };

  renderRadioField = (value, key, options, label) => {
    const children = <Radio.Group options={options} value={value} onChange={this.onChange.bind(this, key)} />;
    return this.renderField(label, children);
  };

  renderMultipleSelect = (label, items, value, onChange) => {
    const children = (
      <BaseCheckboxList showSelectAll={true}
        onChange={onChange}
        checked={value}
        items={items} />
    );
    return this.renderField(label, children);
  };

  renderVideoSearchField = () => {
    const label = this.props.t('EditBannerModal.videoClipUrl');
    const { videoClipUrl, options } = this.state;
    const children = (
      <AutoComplete
        onSearch={this.onVideoClipUrlSearch}
        dataSource={options}
        onChange={this.onChange.bind(this, 'videoClipUrl')}
        value={videoClipUrl} />
    );
    return this.renderField(label, children);
  };

  searchItems = (searchText) => {
    searchCustomPreview(searchText)
      .then(this.setCustomPreviewOptions);
  };

  setCustomPreviewOptions = (response) => {
    const { data } = response.data;
    this.setState({
      options: data
    });
  };

  sortByName = ({ name }, { name: name1 }) => {
    let order = 0;
    if (name > name1) {
      order = 1;
    } else if (name < name1) {
      order = -1;
    }

    return order;
  };

  render() {
    const { t } = this.props;
    const {
      banners,
      caption,
      dataLoaded,
      excluded,
      exclusive,
      overlay_text,
      overlay_title,
      overlay_subtitle,
      properties,
      publishedDate,
      publishedEnd,
      selectedSets,
      studios_id,
      movie_playlists_id,
      scene_playlists_id,
      tags_id,
      title,
      url,
      movies_id,
      scenes_id,
      join_button_text,
      join_button_color,
      join_button_background_color,
      join_button_position
    } = this.state;
    return (
      <ModalDialog
        title={this.getModalTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}>
        <div className="EditBannerModal">
          {this.renderBannerImage()}
          {this.renderEnabledDisabled()}

          <Row gutter={16}>
            <Col span={12}>
              {this.renderInputField(movies_id, 'movies_id', t('EditBannerModal.movieId'))}
            </Col>
            <Col span={12}>
              {this.renderInputField(scenes_id, 'scenes_id', 'Scene ID')}
            </Col>
          </Row>

          {this.renderInputField(title, 'title', t('EditBannerModal.title'))}

          {this.renderInputField(caption, 'caption', t('EditBannerModal.caption'))}

          {this.renderInputField(url, 'url', t('EditBannerModal.url'))}

          {this.renderVideoSearchField()}

          <Row gutter={16}>
            <Col span={12}>
              {this.renderInputField(tags_id, 'tags_id', t('EditBannerModal.tagId'))}
            </Col>
            <Col span={12}>
              {this.renderInputField(studios_id, 'studios_id', t('EditBannerModal.studioId'))}
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              {this.renderInputField(movie_playlists_id, 'movie_playlists_id', t('EditBannerModal.moviePlaylistId'))}
            </Col>
            <Col span={12}>
              {this.renderInputField(scene_playlists_id, 'scene_playlists_id', t('EditBannerModal.scenePlaylistId'))}
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              {this.renderDatePicker(t('EditBannerModal.publishedDate'), publishedDate, momentFormats.date, this.onChange.bind(this, 'publishedDate'))}
            </Col>
            <Col span={12}>
              {this.renderDatePicker(t('EditBannerModal.publishedEnd'), publishedEnd || null, momentFormats.date, this.onChange.bind(this, 'publishedEnd'))}
            </Col>
          </Row>

          <br />
          <Collapse>
            <Collapse.Panel header="Overlay Video Text">
              {this.renderInputField(overlay_title, 'overlay_title', t('EditBannerModal.overlayTop'))}
              {this.renderInputField(overlay_subtitle, 'overlay_subtitle', t('EditBannerModal.overlayMiddle'))}
              {this.renderInputField(overlay_text, 'overlay_text', t('EditBannerModal.overlayBottom'))}

              {this.renderInputField(join_button_text, 'join_button_text', 'Button text')}

              {this.renderField('Button text color', (
                <Row gutter={10}>
                  <Col span={2}>
                    <Input type='color' value={join_button_color} onChange={this.onChange.bind(this, 'join_button_color')} style={{ padding: 0 }} />
                  </Col>
                  <Col span={22}>
                    <Input value={join_button_color} onChange={this.onChange.bind(this, 'join_button_color')} maxLength={7} />
                  </Col>
                </Row>
              ))}

              {this.renderField('Button background color', (
                <Row gutter={10}>
                  <Col span={2}>
                    <Input type='color' value={join_button_background_color} onChange={this.onChange.bind(this, 'join_button_background_color')} style={{ padding: 0 }} />
                  </Col>
                  <Col span={22}>
                    <Input value={join_button_background_color} onChange={this.onChange.bind(this, 'join_button_background_color')} maxLength={7} />
                  </Col>
                </Row>
              ))}

              {this.renderRadioField(join_button_position, 'join_button_position', [
                { label: 'Top Left', value: 1 },
                { label: 'Top Right', value: 2 },
                { label: 'Bottom Left', value: 3 },
                { label: 'Bottom Right', value: 4 },
                { label: 'Center (Default)', value: 5 }
              ], 'Button position')}
            </Collapse.Panel>
          </Collapse>
          <br />

          {dataLoaded ? (
            <React.Fragment>
              {this.renderMultipleSelect(t('EditBannerModal.bannerSets'), banners, selectedSets, this.onMultipleSelectChange.bind(this, 'selectedSets'))}

              {this.renderMultipleSelect(t('EditBannerModal.exclusiveProperties'), properties, exclusive, this.onMultipleSelectChange.bind(this, 'exclusive'))}

              {this.renderMultipleSelect(t('EditBannerModal.excludedProperties'), properties, excluded, this.onMultipleSelectChange.bind(this, 'excluded'))}
            </React.Fragment>
          ) : null}
        </div>
      </ModalDialog>
    );
  }
}

EditBannerModal.propTypes = {
  bannerId: PropTypes.number.isRequired,
  onBannerEdit: PropTypes.func.isRequired
};

export default withTranslation()(EditBannerModal);
