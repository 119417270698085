import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import { Button, Icon, List } from "antd";

import ModalDialog from "../ModalDialog/ModalDialog";

import ImagesLightBox from "../../ImagesLighbox/ImagesLighbox";

import SingleFile from "./SingleFile/SingleFile";

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../services/notification-service/notification.service";

import './UploadImages.scss';

class UploadImages extends Component {
  singleImageUpload = false;

  dragCounter = 0;

  dropRef;

  fileCounter = 0;

  fileUploader;

  images = [];

  modalRef = React.createRef();

  state = {
    drag: false,
    files: [],
    fullScreen: false,
    photoIndex: 0,
    uploading: false
  };

  constructor(props) {
    super(props);
    this.dropRef = React.createRef();
    this.fileUploader = React.createRef();
  }

  componentDidMount() {
    let div = this.dropRef.current;
    if (div) {
      div.addEventListener('dragenter', this.handleDragIn);
      div.addEventListener('dragleave', this.handleDragOut);
      div.addEventListener('dragover', this.preventEventDefault);
      div.addEventListener('drop', this.handleDrop);
    }
  };

  componentWillUnmount() {
    let div = this.dropRef.current;
    if (div) {
      div.removeEventListener('dragenter', this.handleDragIn);
      div.removeEventListener('dragleave', this.handleDragOut);
      div.removeEventListener('dragover', this.preventEventDefault);
      div.removeEventListener('drop', this.handleDrop);
    }
  }

  clearAllImages = () => {
    this.images = [];
    this.setState({
      files: []
    });
  };

  closeDialog = () => {
    this.getModal().closeModal();
  };

  displayFullScreen = (index) => {
    this.setState({
      fullScreen: true,
      photoIndex: index
    });
  };

  fileAdded = (fileName) => {
    return !!this.state.files.find(this.matchFileByName.bind(this, fileName));
  };

  getActions = () => {
    const { t } = this.props;

    return (
      <div className="UploadImages-actions">
        <Button key="cancel" onClick={this.clearAllImages.bind(this)}>
          {t('UploadImages.clearAllImages')}
        </Button>
        <div>
          <Button key="cancel" onClick={this.onCancel.bind(this)}>
            {t('UploadImages.cancel')}
          </Button>
          <Button key="upload"
            onClick={this.uploadImages}
            disabled={!this.isValid() || !this.isSingleImageUploadCorrect()}>
            {this.getUploadButtonText()}
          </Button>
        </div>
      </div>
    );
  };

  getDropContainerClasses = () => {
    const classes = ['UploadImages-browse-wrapper'];
    if (this.state.drag) {
      classes.push('Dragging');
    }
    if (this.state.files.length) {
      classes.push('FilesListActive');
    }

    return classes;
  };

  getImageCategories = () => {
    return [];
  };

  getModal = () => {
    return this.modalRef.current;
  };

  getModalTitle = () => {
    const { title } = this.props;
    return title ? title : this.props.t('UploadImages.uploadImages');
  };

  getUploadButtonText = () => {
    return this.props.t('UploadImages.upload');
  };

  getUploadingFiles = () => {
    const files = [];
    let item = 0;
    let itemCount = this.state.files.length;
    let currentItem;
    while (item < itemCount) {
      currentItem = this.state.files[item];
      if (currentItem.selected) {
        files.push({
          file: currentItem.file,
          type: currentItem.type
        });
      }
      item++;
    }

    return files;
  };

  handleDragIn = (e) => {
    this.preventEventDefault(e);
    this.dragCounter++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ drag: true });
    }
  };

  handleDragOut = (e) => {
    this.preventEventDefault(e);
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false })
    }
  };

  handleDrop = (e) => {
    this.preventEventDefault(e);
    this.setState({ drag: false });
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.processSelectedFiles(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
    }
  };

  isSingleImageUploadCorrect = () => {
    const { files } = this.state;
    const selectedFiles = files.filter(this.matchSelectedFiles);

    return !this.singleImageUpload || selectedFiles.length < 2;
  };

  isValid = () => {
    const { files } = this.state;
    let isValid = !!files.length;
    let item = files.length;
    let currentItem;
    while (isValid && item) {
      item--;
      currentItem = files[item];
      isValid = isValid && (!currentItem.selected || currentItem.type)
    }

    return isValid;
  };

  matchFileById = (id, file) => {
    return file.id === id;
  };

  matchFileByName = (name, file) => {
    return file.name === name;
  };

  matchSelectedFiles = item => {
    return item.selected;
  };

  onBrowsedFileSelected = (e) => {
    this.processSelectedFiles(e.target.files);
  };

  onCancel = () => {
    this.closeDialog();
  };

  onFileRead = (id, e) => {
    const files = cloneDeep(this.state.files);
    const index = files.findIndex(this.matchFileById.bind(this, id));
    const url = e.target.result;
    files[index].url = url;
    this.images[index] = url;
    this.setState({
      files: files
    });
  };

  onTypeChange = (index, value) => {
    this.setState(prevState => {
      const files = cloneDeep(prevState.files);
      files[index].type = value;
      return { files };
    });
  };

  // onUploadError = () => { 
  //   this.getModal().clearLoading();
  //   displayErrorNotification({
  //     duration: 3,
  //     message: this.props.t('UploadImages.uploadFailedMsg')
  //   });
  // };

  // onUploadSuccess = () => {
  //   this.getModal().clearLoading();
  //   displaySuccessNotification({
  //     duration: 3,
  //     message: this.props.t('UploadImages.uploadSuccessMsg')
  //   });
  //   this.closeDialog();
  //   this.props.onUploadFinished();
  // };

  preventEventDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  processSelectedFiles = (files) => {
    let fileList = cloneDeep(this.state.files);
    const types = this.getImageCategories();
    for (let i = 0; i < files.length; i++) {
      if (files[i].name && !this.fileAdded(files[i].name)) {
        this.fileCounter++;
        fileList.push({
          name: files[i].name,
          id: this.fileCounter,
          selected: true,
          file: files[i],
          type: types[0]
        });
        this.images.push('');
        this.readURL(files[i], this.fileCounter);
      }
    }
    this.setState({ files: fileList });
  };

  selectFile = () => {
    const { files } = this.state;
    if (!this.singleImageUpload || files.length < 1) {
      this.fileUploader.current.click();
    }
  };

  readURL(file, id) {
    const reader = new FileReader();
    reader.onload = this.onFileRead.bind(this, id);

    reader.readAsDataURL(file);
  }

  removeFile = (id) => {
    this.setState(prevState => {
      const files = cloneDeep(prevState.files);
      const index = files.findIndex(this.matchFileById.bind(this, id));
      files.splice(index, 1);
      this.images.splice(index, 1);
      return {
        files: files
      };
    });
  };

  renderDragDrop = () => {
    const { t } = this.props;
    return (
      <div className="UploadImages-drag-drop">
        {!this.singleImageUpload &&
          <div className="DragDropText">
            <Icon type="cloud-upload" />
            <div>{t('UploadImages.dragDropMsg')}</div>
            <div>{t('UploadImages.or')}</div>
          </div>
        }
        <input type="file" id="file" ref={this.fileUploader} onChange={this.onBrowsedFileSelected} />
        <div onClick={this.selectFile.bind(this)}>{t('UploadImages.browseFiles')}</div>
      </div>
    );
  };

  renderFilesList = () => {
    return (
      <List className="UploadImages-list"
        dataSource={this.state.files}
        renderItem={this.renderListItem} />
    );
  };

  renderImagesLightBox = () => {
    let view = null;
    if (this.state.fullScreen) {
      view = (<ImagesLightBox images={this.images}
        currentIndex={this.state.photoIndex}
        onClose={this.turnOffFullScreen} />
      );
    }
    return view;
  };

  renderListItem = (item) => {
    const index = this.state.files.findIndex(this.matchFileById.bind(this, item.id));
    const { hideFilesDropdown } = this.props;

    return (
      <SingleFile
        name={item.name}
        index={index}
        id={item.id}
        imageCategories={this.getImageCategories()}
        selected={item.selected}
        url={item.url}
        displayFullScreen={this.displayFullScreen}
        onTypeChange={this.onTypeChange}
        removeFile={this.removeFile}
        type={item.type}
        updateSelectedFiles={this.updateSelectedFiles}
        hideFilesDropdown={hideFilesDropdown}
      />
    );
  };

  turnOffFullScreen = () => {
    this.setState({
      fullScreen: false
    });
  };

  updateSelectedFiles = (id, event) => {
    const selected = event.target.checked;
    this.setState(prevState => {
      const files = cloneDeep(prevState.files);
      const index = files.findIndex(this.matchFileById.bind(this, id));
      files[index].selected = selected;
      return {
        files: files
      };
    });
  };

  uploadImage = (file) => {
    return this.props.uploadImages(file.file, file.type);
  };

  onUploadError = (err, fileName) => {
    const msg = err?.response?.data?.error ?? '';
    const descr = fileName + ' - ' + msg;

    displayErrorNotification({
      duration: 8,
      message: this.props.t('UploadImages.uploadFailedMsg'),
      description: descr
    });
    console.log(err);
  };

  uploadImages = () => {
    const { onUploadImageClick, replaceImage } = this.props;
    const files = this.getUploadingFiles();

    if (onUploadImageClick) {
      onUploadImageClick(files, this.images);
      this.closeDialog();
    } else {
      const message = this.props.t('UploadImages.uploadingPleaseWaitMsg');
      this.getModal().setLoading(message);

      if (replaceImage) {
        replaceImage(files[0].file, this.closeDialog);
      } else {
        const notUploadedFiles = [];
        const promises = [];
        let errorOnUpload = false;
        let counter = 1;

        // Every third image upload will be synchronous, 3 uploads at the time
        const resolvePromisesSeq = async () => {
          for (const singleFile of files) {
            if (counter % 3 !== 0) {
              promises.push(this.uploadImage(singleFile)
                // eslint-disable-next-line
                .catch(err => {
                  const fileName = singleFile?.file?.name ?? '';
                  this.onUploadError(err, fileName);
                  notUploadedFiles.push(fileName);
                  errorOnUpload = true;
                }));
            } else {
              await this.uploadImage(singleFile)
                // eslint-disable-next-line
                .catch(err => {
                  const fileName = singleFile?.file?.name ?? '';
                  this.onUploadError(err, fileName);
                  notUploadedFiles.push(fileName);
                  errorOnUpload = true;
                });
            }
            counter++;
          }
          return Promise.all(promises);
        };

        resolvePromisesSeq()
          .then(() => {
            this.props.onUploadFinished();
            this.getModal().clearLoading();

            if (notUploadedFiles.length !== 0) {
              this.setState(prevState => {
                const files = prevState.files.filter(item => notUploadedFiles.includes(item.name));
                return { files }
              });
            }
          })
          .finally(() => {
            if (!errorOnUpload) {
              displaySuccessNotification({
                duration: 3,
                message: this.props.t('UploadImages.uploadSuccessMsg')
              });
              this.closeDialog();
            }
          })
      }
    }
  };

  render() {
    const classes = this.getDropContainerClasses();
    let imagesLightBox = this.state.fullScreen ? this.renderImagesLightBox() : null;
    return (
      <ModalDialog title={this.getModalTitle()}
        actions={this.getActions()}
        forwardedRef={this.modalRef}
        showMaximize={true}>
        <div className={classes.join(' ')} ref={this.dropRef}>
          {this.renderDragDrop()}
          {this.renderFilesList()}
          {imagesLightBox}
        </div>
      </ModalDialog>
    );
  }
}

UploadImages.propTypes = {
  onUploadFinished: PropTypes.func,
  uploadImages: PropTypes.func,
  onUploadImageClick: PropTypes.func,
  title: PropTypes.string,
  hideFilesDropdown: PropTypes.bool
};

export default UploadImages;
