import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';

import BaseDelayedInput from "../BaseDelayedInput/BaseDelayedInput";

import './BaseCheckboxList.scss';

class BaseCheckboxList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      items: [],
      filteredItems: []
    }
  }

  componentDidMount() {
    const checked = this.props.checked ? this.props.checked : [];
    this.setState({
      items: this.props.items,
      checked: checked
    })

    if (!!this.props.showSearch) {
      this.setState({ filteredItems: this.props.items })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({ items: this.props.items })
    }

    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked })
    }

    if (prevProps.showSearch !== this.props.showSearch) {
      this.setState({ filteredItems: this.props.items })
    }
  }

  areAllFilteredItemsChecked = (checked, items) => {
    let allChecked = checked.length >= items.length;
    // If checked has less elements than items that means that at least one of the items
    // is not checked so don't check everything, just return false
    if (allChecked) {
      let item = items.length;
      while (allChecked && item) { // As long as allChecked is true keep going
        item--;
        // The first time an item is not checked set allChecked to false to break the while loop
        allChecked = checked.indexOf(items[item].id) !== -1;
      }
    }

    return allChecked;
  };

  filterByName = (search, { name }) => {
    return name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
  };

  isEverythingChecked = (checked, items, filteredItems) => {
    const { showSearch } = this.props;
    let allChecked;
    if (showSearch) {
      allChecked = this.areAllFilteredItemsChecked(checked, filteredItems);
    } else {
      allChecked = checked?.length === items?.length;
    }
    return allChecked;
  };

  mapItem = item => {
    const { name, id } = item;
    const { checked } = this.state;
    return (
      <div
        className="BaseCheckboxList-Item"
        key={`base-checkbox-item-${id}`}
        onClick={this.onChange.bind(this, id)}
        title={id + ' - ' + name}>
        <Checkbox checked={checked.includes(id)}
          value={id}
          indeterminate={false} />
        {name}
      </div>
    );
  };

  notifyChange = () => {
    const { checked } = this.state;
    this.props.onChange(checked);
  };

  onChange = id => {
    this.setState(prevState => {
      let checked = prevState.checked.slice();
      if (checked.includes(id)) {
        const index = checked.indexOf(id);
        checked.splice(index, 1);
      } else {
        checked.push(id);
      }
      return { checked };
    }, this.notifyChange);
    this.forceUpdate();
  };

  renderHeader = () => {
    const { showSearch, showSelectAll } = this.props;
    return showSearch || showSelectAll ? (
      <div className="Header">
        {this.renderSelectAll()}
        {this.renderSearch()}
      </div>
    ) : null;
  };

  renderSearch = () => {
    const { showSearch, t } = this.props;
    return showSearch ? (
      <BaseDelayedInput className="Search"
        placeholder={t('BaseCheckboxList.search')}
        onChange={this.searchItems}
        allowClear={true} />
    ) : null;
  };

  renderSelectAll = () => {
    const { showSelectAll, t } = this.props;
    const { checked, filteredItems, items } = this.state;
    const allChecked = this.isEverythingChecked(checked, items, filteredItems);
    const key = allChecked ? 'unselectAll' : 'selectAll'
    return showSelectAll ? (
      <div className="SelectAll" onClick={this.toggleAllChecked}>
        <Checkbox checked={allChecked} />
        {t(`BaseCheckboxList.${key}`)}
      </div>
    ) : null;
  };

  searchItems = (search) => {
    this.setState(prevState => {
      const { items } = prevState;
      return {
        filteredItems: items.filter(this.filterByName.bind(this, search))
      };
    });
  };

  toggleAllChecked = () => {
    this.setState(prevState => {
      const { checked, items } = prevState;
      let isChecked = [];

      if (checked.length !== items.length) {
        isChecked = prevState.items.map(item => item.id);
      }

      return {
        checked: isChecked
      };
    }, this.notifyChange);
    this.forceUpdate();
  };

  render() {
    const { filteredItems, items } = this.state;
    const { className, showSearch } = this.props;
    const classes = ['BaseCheckboxList'];
    if (className) {
      classes.push(className);
    }
    const data = showSearch ? filteredItems : items;
    return (
      <div className={classes.join(' ')}>
        {this.renderHeader()}
        <div className="BaseCheckboxList-inner">
          {data.map(this.mapItem)}
        </div>
      </div>
    );
  }
}

BaseCheckboxList.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.array,
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  showSearch: PropTypes.bool,
  showSelectAll: PropTypes.bool
};

export default withTranslation()(BaseCheckboxList);