import React, { Component } from 'react';
import { Link } from "@reach/router";
import { withTranslation } from "react-i18next";
import { Button, Checkbox, Icon, Empty } from "antd";
import PropTypes from 'prop-types';

import BaseCard from "../../../../../../../../../components/BaseCard/BaseCard";
import EditBannerModal from "../BannerDetails/EditBannerModal/EditBannerModal";
import ImageComponent from "../../../../../../../../../components/ImageComponent/ImageComponent";
import BaseDropdown from "../../../../../../../../../components/BaseDropdown/BaseDropdown";
import ConfirmationModal from "../../../../../../../../../components/modal/ConfirmationModal/ConfirmationModal";

import {
  displayErrorNotification,
  displaySuccessNotification
} from "../../../../../../../../../services/notification-service/notification.service";
import {
  getBannerDetailsRoute
} from "../../../../../../../../../services/navigation/banners-navigation/banners-navigation-routes.service";
import { findLargesBannerImage, uploadBannerImage } from '../../../../../../../../../services/images-service/images.service';

import { deleteBanner } from '../../../../../../../../../services/banners-service/banners.service';

import { ModalController } from "../../../../../../../../../controllers/modal-controller/modal.controller";
import ReplacePictureModal from '../ReplacePictureModal/ReplacePictureModal';

import AddToSetModal from "../AddToSetModal/AddToSetModal";

import './BannerCard.scss';
import { showBannerDetails } from "../../../../../../../../../services/navigation/banners-navigation/banners-navigation.service";

class BannerCard extends Component {

  deleteBanner = (bannerId) => {
    const message = this.props.t('BannerCard.deletingBannerMsg');
    this.props.setLoading(message);

    deleteBanner(bannerId)
      .then(this.onDeleteBannerSuccess)
      .catch(this.onDeleteBannerFailure);
  };

  getActions = () => {
    const { t, banner, showEdit } = this.props;
    let { id } = banner;

    let actions = [
      <div onClick={this.showAddToSetModal}>
        {t('BannerCard.addToSet')}
      </div>,
      <Link to={getBannerDetailsRoute(id)}>
        {t('BannerCard.viewDetails')}
      </Link>,
      <div onClick={this.showReplacePictureModal}>
        {t('BannerCard.replacePicture')}
      </div>,
      <div onClick={this.showDeleteBannerConfirmationModal}>
        {t('BannerCard.deleteBanner')}
      </div>
    ];

    if (showEdit) {
      const editBtn = (
        <div onClick={this.showEditBannerDialog}>
          {t('BannerCard.edit')}
        </div>
      );

      actions.splice(1, 0, editBtn);
    }

    return actions;
  };

  /**
   * Returns title
   *
   * @returns {String}
   */
  getTitle = () => {
    const { t } = this.props;
    let { exclusiveExcluded, publishedDate, title } = this.props.banner;
    if (!title) {
      title = t('BannerCard.nA');
    }
    return (
      <div className="BannerCard-title">
        <div className="Title" title={title}>{title}</div>
        {this.renderBannerStatuses('Top', exclusiveExcluded, publishedDate, title)}
      </div>
    );
  };

  getSettingsMenu = () => {
    return (
      <BaseDropdown items={this.getActions()} menuClassName="BannerCard-dropdown">
        <Icon type="setting" />
      </BaseDropdown>
    );
  };

  onDeleteBannerFailure = () => {
    this.props.setLoading('');
    const { t } = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('BannerCard.bannerDeletionFailureMsg')
    });
  };

  onDeleteBannerSuccess = () => {
    this.props.setLoading('');
    this.props.onDeleteBanner();
    const { t } = this.props;
    displaySuccessNotification({
      duration: 3,
      message: t('BannerCard.bannerDeletionSuccessMsg')
    });
  };

  /**
   * Returns banner image
   *
   * @returns {HTMLElement| null}
   */
  renderBannerImage = () => {
    const { image, videoClipUrl } = this.props.banner;
    let img = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    const desktopImg = findLargesBannerImage(image);

    if (desktopImg) {
      img = (
        <ImageComponent url={desktopImg} onClick={this.showBannerDetailsPage} className={videoClipUrl ? 'HasVideoClip' : ''} />
      );
    }
    return img;
  };

  replaceBannerPicture = (file, type) => {
    const { id } = this.props.banner;
    return uploadBannerImage(id, type, file);
  };

  renderBannerStatus = (state, tooltip, key) => {
    return (
      <div className="BannerState" key={key} title={tooltip}>
        {state}
      </div>
    );
  };

  renderBannerStatuses = (className, exclusiveExcluded, publishedDate, title) => {
    const states = [
      this.renderExclusiveExcluded(exclusiveExcluded),
      this.renderMovieName(title),
      ...this.renderPublished(publishedDate)
    ];
    const classes = ['Statuses', className];
    return (
      <div className={classes.join(' ')}>
        {states}
      </div>
    );
  };

  showDeleteBannerConfirmationModal = () => {
    const { t } = this.props;
    const { id, banners_sets = [] } = this.props.banner;

    let deleteQuestion = t('BannerCard.deleteQuestion');
    if (banners_sets.length) {
      deleteQuestion = t('BannerCard.deleteFromSetsQuestion');
    }

    const modal = (
      <ConfirmationModal title={t('BannerCard.confirmDelete')}
        message={deleteQuestion}
        yesBtnText={t('BannerCard.deleteBanner')}
        noBtnText={t('BannerCard.cancel')}
        confirm={this.deleteBanner.bind(this, id)} />
    );
    ModalController.showModal(modal);
  };

  showEditBannerDialog = () => {
    const { banner, onBannerEdit } = this.props;
    const { id, } = banner;
    const modal = (
      <EditBannerModal bannerId={id}
        onBannerEdit={onBannerEdit} />
    );
    ModalController.showModal(modal);
  };

  renderExclusiveExcluded = (exclusiveExcluded) => {
    let view = null;
    if (exclusiveExcluded && exclusiveExcluded.exclusive_excluded.length) {
      const { t } = this.props;
      view = this.renderBannerStatus((
        <i className="fas fa-lock" />
      ), t('BannerCard.hasPropertyExclusivity'), 'exclusivity');
    }

    return view;
  };

  renderMovieName = (title) => {
    let view = null;
    if (title) {
      view = this.renderBannerStatus((
        <i className="fas fa-film" />
      ), title, 'movieName');
    }

    return view;
  };

  renderPublished = (publishedDate) => {
    const states = [];
    if (publishedDate) {
      const { t } = this.props;
      states.push(this.renderBannerStatus((
        <i className="fas fa-calendar-alt" />
      ), t('BannerCard.hasSchedule'), 'hasSchedule'));
      states.push(this.renderBannerStatus((
        <i className="fas fa-check" />
      ), t('BannerCard.published'), 'published'))
    }

    return states;
  };

  showAddToSetModal = () => {
    const { id, banners_sets } = this.props.banner;
    const modal = <AddToSetModal
      bannerId={id}
      bannerSets={banners_sets}
      onBannerSetChanged={this.props.onBannerSetChanged}
    />;
    ModalController.showModal(modal);
  };

  showBannerDetailsPage = () => {
    const { id } = this.props.banner;
    showBannerDetails(id);
  };

  showReplacePictureModal = () => {
    const { banner, onBannerEdit } = this.props;
    const { id } = banner;
    const modal = <ReplacePictureModal
      bannerId={id}
      uploadImages={this.replaceBannerPicture}
      onUploadFinished={onBannerEdit}
    />;
    ModalController.showModal(modal);
  }

  getFooter = () => {
    const { showCheckBox, bannersToDelete, t } = this.props;
    const { status, id } = this.props.banner;
    return (
      <div className='FooterWrapper'>
        <Button onClick={null} className={status ? 'Enabled' : 'Disabled'}>
          {status ? 'Enabled' : 'Disabled'}
        </Button>
        <div className='Delete'>
          <span>{t('BannerCard.deleteLabel')}&nbsp;</span>
          <Checkbox
            className='Checkbox'
            onChange={showCheckBox.bind(this, id)}
            checked={bannersToDelete.includes(id)}
          />
        </div>
      </div>
    )
  };

  render() {
    let { exclusiveExcluded, publishedDate, id, title } = this.props.banner;
    return (
      <BaseCard title={this.getTitle()}
        className="BannerCard"
        extra={this.getSettingsMenu()}
        footer={this.getFooter()}
      >
        <div className="BannerCard-body" title={id}>
          {this.renderBannerStatuses('Bottom', exclusiveExcluded, publishedDate, title)}
          {this.renderBannerImage()}
        </div>
      </BaseCard>
    );
  }
}

BannerCard.propTypes = {
  banner: PropTypes.object.isRequired,
  onBannerEdit: PropTypes.func,
  showEdit: PropTypes.bool,
  showCheckBox: PropTypes.func.isRequired,
  bannersToDelete: PropTypes.arrayOf(PropTypes.number)
};

export default withTranslation()(BannerCard);
