import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { CSVLink } from "react-csv";
import { Button } from 'antd';

import MoviesViewAbstract from '../../MoviesView/MoviesViewAbstract/MoviesViewAbstract';
import LicensorMoviesMainView from "./LicensorMoviesMainView/LicensorMoviesMainView";

import { getMoviesByLicensorId, getMoviesExport } from '../../../../../../../../../services/movies-service/movies.service';
import { displayErrorNotification, displaySuccessNotification } from '../../../../../../../../../services/notification-service/notification.service';
import { DocumentTitleController } from "../../../../../../../../../controllers/title-controller/title.controller";
import { getLicensorMoviesRoute } from "../../../../../../../../../services/navigation/licensors-navigation/licensors-navigation-routes.service";

class LicensorMovies extends MoviesViewAbstract {
  licensorId;

  constructor(props) {
    super(props);
    this.licensorId = props.licensorId;
  }

  componentDidMount() {
    super.componentDidMount();
    this.setDocumentTitle();
  }

  fetchMovies = page => {
    const message = this.props.t('MoviesViewAbstract.loadingPleaseWait');
    this.setLoading(message);
    const filters = this.getFilters();
    getMoviesByLicensorId(this.licensorId, page, filters)
      .then(this.loadMovies)
      .catch(this.onFetchMoviesFailure);
  };

  getMoviesRoute = () => {
    const { licensorId } = this.props;
    return getLicensorMoviesRoute(licensorId);
  };

  onFetchMoviesFailure = () => {
    const { t } = this.props;
    displayErrorNotification({
      duration: 3,
      message: t('LicensorMovies.fetchMoviesFailure')
    });
  };

  onExportMovies = () => {
    const { licensorId, t } = this.props;
    const message = t('StarMovies.exportingMsg');
    this.setLoading(message);

    getMoviesExport({ licensors_id: licensorId })
      .then(res => {
        this.setLoading('');
        displaySuccessNotification({
          duration: 0,
          message: t('StarMovies.moviesExportSuccess'),
          description: (
            <CSVLink data={res.data} filename={"exported-movies.csv"}>{t('StarMovies.clickToDownloadCSV')}</CSVLink>
          )
        });
      })
      .catch(err => {
        this.setLoading('');
        displayErrorNotification({
          duration: 3,
          message: t('StarMovies.moviesExportFailed')
        });
        console.log(err);
      });
  };

  getAdditionalActions = () => <Button onClick={this.onExportMovies}>Export</Button>;

  renderMainViewRoute = () => {
    const props = this.getMainViewProps();
    props.licensorId = this.licensorId;
    props.additionalActions = this.getAdditionalActions();

    return <LicensorMoviesMainView {...props} />
  };

  setDocumentTitle = () => {
    const { licensorName, t } = this.props;
    const title = t('LicensorMovies.documentTitle', { name: licensorName });
    DocumentTitleController.setDocumentTitle(title);
  };
}

LicensorMovies.propTypes = {
  licensorName: PropTypes.string
};

export default withTranslation()(LicensorMovies);
